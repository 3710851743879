import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/login/silent",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
      <Button 
        id="qsSignupBtn"
        color="secondary"
        className="button-companion"
        onClick={handleSignUp}>
        Sign Up
      </Button>
  );
};
