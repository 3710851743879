import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faPowerOff, faUser, faVideo, faFilm, faChartArea, faList, faFilePowerpoint, faPrint, faLock, faBuilding} from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faVideo);
  library.add(faFilm);
  library.add(faChartArea);
  library.add(faList);
  library.add(faFilePowerpoint);
  library.add(faPrint);
  library.add(faLock);
  library.add(faBuilding);
}

export default initFontAwesome;
