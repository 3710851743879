import React, { useState, Fragment, useEffect } from "react";
import { NavLink as RouterNavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginButton } from "./buttons/login-button";
import { SignupButton } from "./buttons/signup-button";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { getAllDataForSelf } from "../core/services/gutcheck-api.service";

type NavState = {
  fetched: boolean,
  user: any,
  error: any,
  ok: any,
  currentOrg: any,
  currentOrgTag: string
};

const initState: NavState = {
  fetched: false,
  user: null,
  error: null,
  ok: null,
  currentOrg: null,
  currentOrgTag: ""
};

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [state, setState] = useState(initState);

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  let location = useLocation();

  useEffect(() => {
    let isMounted = true;

    // Grab organization tag from URL if it's there
    const newOrganizationTag = location.pathname.indexOf("/o/") === 0 ? location.pathname.replace(/^\/o\//, "").replace(/\/.*/, "") : "";

    const fetchProjectData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAllDataForSelf(accessToken);

      if (!isMounted) {
        return;
      }

      if (data && data.user) {
        const prevOrganizationTag = state.currentOrgTag;
        let currentOrg = null;

        if (data.user.organizations && data.user.organizations.length > 0) {
          const currentOrganizationTag = newOrganizationTag ? newOrganizationTag : prevOrganizationTag ? prevOrganizationTag : data.user.organizations[0].tag;

          currentOrg = data.user.organizations?.find((organization: any) => organization.tag === currentOrganizationTag);
        }

        setState({
          ...state,
          fetched: true,
          user: data.user,
          error: error,
          ok: data.ok,
          currentOrg: currentOrg
        });
      }
    };

    // If the user is authenticated...
    // We only load their data if we haven't done it yet or the new org isn't the same as the current org
    if (!isLoading && isAuthenticated && (!state.fetched || newOrganizationTag !== state.currentOrg?.tag)) {
      fetchProjectData();
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, isLoading, location]);

  if (isAuthenticated && !state.fetched) return <Fragment />


  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  const handleOrgChange = (event: any) => {
    const organizationTag = event.target.attributes.data.value;
    if (organizationTag) {
      const organization = state.user.organizations.find((organization: any) => organization.tag === organizationTag);
      setState({
        ...state,
        currentOrg: organization
      });
    }

  }

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">
        <Container>
          <NavbarBrand className="logo" />
          { state.currentOrg && (
            <div>
              <RouterNavLink to={`/o/${state.currentOrg?.tag}`} className="nav-collapsed-company-name">{state.currentOrg?.name}</RouterNavLink>
              </div>
          )}
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto gc-name" navbar>
              <NavItem key="1">
                <NavLink
                  tag={RouterNavLink}
                  to={isAuthenticated ? "/o/" + state.currentOrg?.tag : "/"}>
                  GutCheck
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="d-none d-md-block nav-auth" navbar>
              {!isAuthenticated && (
                <Fragment>
                  <NavItem key="signup">
                    <SignupButton />
                  </NavItem>
                  <NavItem key="2">
                    <LoginButton />
                  </NavItem>
                </Fragment>
              )}
              {isAuthenticated && user && (
                <UncontrolledDropdown nav inNavbar>
                  <div className="nav-company-name">{state.currentOrg?.name}</div>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    {state.user && state.user.organizations.map((organization: any) => {
                      return (
                        <DropdownItem
                          tag={RouterNavLink}
                          to={`/o/${organization.tag}`}
                          activeclassname="router-link-active"
                          //onClick={handleOrgChange}
                          data={organization.tag}
                          key={organization.tag}
                          className="nav-dropdown-org-selector"

                        >{organization.name}</DropdownItem>
                      )
                    })}
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeclassname="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                    {user['https://gutcheck.svpvc.com/roles']?.includes('admin') && (
                      <DropdownItem
                        tag={RouterNavLink}
                        to="/admin"
                        className="dropdown-admin"
                        activeclassname="router-link-exact-active"
                      >
                        <FontAwesomeIcon icon="lock" className="mr-3" /> Admin
                      </DropdownItem>
                    )}
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem key="3">
                  <Button
                    id="qsSignupBtn"
                    color="secondary"
                    block
                    onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
                  >
                    Sign Up
                  </Button>
                </NavItem>
                <NavItem key="4">
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && user && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem key="5">
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem className="nav-collapsed-section-header" key="org-switch">
                <FontAwesomeIcon icon="building" className="mr-3" />
                  <span>Switch Organizations:</span>
                </NavItem>
                {state.user && state.user.organizations.map((organization: any) => {
                  return (
                    <NavItem className="nav-collapsed-section-subitem" key={`org-switch-${organization.tag}`}>
                      <RouterNavLink
                        // tag={RouterNavLink}
                        to={`/o/${organization.tag}`}
                        // activeclassname="router-link-active"
                        //onClick={handleOrgChange}
                        // data={organization.tag}
                        key={`org-switch-link-${organization.tag}`}
                        className="nav-dropdown-org-selector"

                      >{organization.name}
                      </RouterNavLink>
                    </NavItem>
                  )
                })
              }
                <NavItem key="2">
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                    // activeclassname="router-link-exact-active"
                  >
                    Profile
                  </RouterNavLink>
                </NavItem>
                {user['https://gutcheck.svpvc.com/roles']?.includes('admin') && (
                  <NavItem key="3">
                    <FontAwesomeIcon icon="lock" className="mr-3" />
                    <RouterNavLink
                      to="/admin"
                      // activeclassname="router-link-exact-active"
                    >
                      Admin
                    </RouterNavLink>
                  </NavItem>
                )}
                <NavItem key="4">
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
